<template>
  <section id="skills" class="relative max-w-screen-lg mx-auto pt-28 lg:mb-40">
    <div class="container flex flex-col-reverse items-center gap-12 lg:flex-row">
      <div class="flex flex-col flex-1">
        <div class="lg:-ml-3 pl-3 pt-3 border-l-2 border-mj-php-mellow mb-8 rounded">
          <p class="text-mj-php-mellow text-xs mb-2 uppercase">Check out my</p>
          <h2 class="pb-3 text-3xl text-mj-php-mellow md:text-4xl">Skills</h2>
        </div>
        <ul class="flex flex-wrap gap-x-2 md:gap-x-8 gap-y-4">
          <li class="icon-skills">
            <img alt="JavaScript Icon" src="../assets/icons/javascript.svg">
            <span class="text-sm">JavaScript</span>
          </li>
          <li class="icon-skills">
            <img alt="VueJS Icon" src="../assets/icons/react.svg">
            <span class="text-sm">ReactJs</span>
          </li>
          <li class="icon-skills">
            <img alt="VueJS Icon" src="../assets/icons/vue-js.svg">
            <span class="text-sm">VueJS</span>
          </li>
          <li class="icon-skills">
            <img alt="PHP Icon" src="../assets/icons/php-logo.svg">
            <span class="text-sm">PHP</span>
          </li>
          <li class="icon-skills">
            <img alt="HTML Icon" src="../assets/icons/html-5.svg">
            <span class="text-sm">HTML</span>
          </li>
          <li class="icon-skills">
            <img alt="CSS Icon" src="../assets/icons/css3.svg">
            <span class="text-sm">CSS</span>
          </li>
          <li class="icon-skills">
            <img alt="SASS Icon" src="../assets/icons/sass.svg">
            <span class="text-sm">SASS</span>
          </li>
          <li class="icon-skills">
            <img alt="Tailwindcss Icon" src="../assets/icons/tailwindcss.svg">
            <span class="text-sm">Tailwindcss</span>
          </li>
          <li class="icon-skills">
            <img alt="Bootstrap Icon" src="../assets/icons/bootstrap-5-1.svg">
            <span class="text-sm">Bootstrap</span>
          </li>
          <li class="icon-skills">
            <img alt="Webpack Icon" src="../assets/icons/webpack.svg">
            <span class="text-sm">Webpack</span>
          </li>
          <li class="icon-skills">
            <img alt="Git Icon" src="../assets/icons/git.svg">
            <span class="text-sm">Git</span>
          </li>
          <li class="icon-skills">
            <img alt="WordPress Icon" src="../assets/icons/wordpress.svg">
            <span class="text-sm">WordPress</span>
          </li>
          <li class="icon-skills">
            <img alt="Shopify Icon" src="../assets/icons/shopify.svg">
            <span class="text-sm">Shopify</span>
          </li>
          <li class="icon-skills">
            <img alt="Linux Icon" src="../assets/icons/linux-tux.svg">
            <span class="text-sm">Linux</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Skills',
  components: {
    //
  }
}
</script>
