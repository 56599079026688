<template>
  <section id="projects" class="relative max-w-screen-lg mx-auto pt-28 lg:mb-40">
    <div class="container flex flex-col-reverse items-center gap-12 lg:flex-row">
      <div class="flex flex-col flex-1">
        <div class="lg:-ml-3 pl-3 pt-3 border-l-2 border-mj-php-mellow mb-8 rounded">
          <p class="text-mj-php-mellow text-xs mb-2">TAKE A LOOK AT SOME OF MY</p>
          <h2 class="pb-3 text-3xl text-mj-php-mellow md:text-4xl">Projects</h2>
        </div>

<!-- Virgin Media -->
        <div class="relative flex flex-col-reverse lg:flex-row w-full mb-14">
          <div>
            <a href="https://careers.virginmedia.com/" target="_blank" rel="noopener"><img class="transition duration-200 opacity-60 hover:opacity-80" src="../assets/virgin.png" alt="Virgin Media Project"></a>
          </div>
          <div class="lg:absolute right-0 top-10 lg:w-1/2 lg:text-right">
            <h3 class="text-2xl mb-4 text-mj-php-mellow">Virgin Media Careers</h3>
            <div class="bg-mj-raisin-light p-4 rounded mb-4 shadow-md">
              <p>Custom website fully dedicated to finding new opportunities with Virgin Media United Kingdom. <span class="text-mj-php-mellow">WordPress</span> theme with funnelback integration. </p>
            </div>
            <ul class="flex lg:flex-row-reverse gap-x-5 font-mono text-sm mb-3 lg:pl-28 flex-wrap gap-y-2">
              <li>Webpack</li>
              <li>JavaScript</li>
              <li>PHP</li>
              <li>SCSS</li>
              <li>HTML</li>
              <li>WordPress</li>
              <li>ACF</li>
              <li>Funnelback</li>
            </ul>
            <div class="flex items-center lg:flex-row-reverse gap-5 mb-6 lg:mb-0">
              <a href="https://careers.virginmedia.com/" title="External Link" rel="noopener" target="_blank"><ExternalLinkIcon class="w-6 h-6 text-mj-lavender transition duration-200 hover:text-mj-php-mellow" /></a>
            </div>
          </div>
        </div>

<!-- Sainsburys -->
        <div class="relative flex flex-col-reverse lg:flex-row-reverse w-full mb-14">
          <div>
            <a href="https://sainsburys.jobs/" target="_blank" rel="noopener"><img class="transition duration-200 opacity-60 hover:opacity-80" src="../assets/sainsburys.png" alt="Sainsbury Project"></a>
          </div>
          <div class="lg:absolute left-0 top-10 lg:w-1/2 lg:text-left">
            <h3 class="text-2xl mb-4 text-mj-php-mellow">Sainsbury's Jobs</h3>
            <div class="bg-mj-raisin-light p-4 rounded mb-4 shadow-md">
              <p>Sainsbury's UK be inspired and find your next career step. Tailored <span class="text-mj-php-mellow">WordPress</span> theme, a friendly design with easy to navigate layout.</p>
            </div>
            <ul class="flex lg:flex-row gap-x-5 font-mono text-sm mb-3 lg:pr-28 flex-wrap gap-y-2">
              <li>HTML</li>
              <li>CSS</li>
              <li>PHP</li>
              <li>JavaScript</li>
              <li>Webpack</li>
              <li>Funnelback</li>
              <li>WordPress</li>
              <li>ACF</li>
            </ul>
            <div class="flex items-center lg:flex-row gap-5 mb-6 lg:mb-0">
              <a href="https://sainsburys.jobs/" title="External Link" target="_blank" rel="noopener"><ExternalLinkIcon class="w-6 h-6 text-mj-lavender transition duration-200 hover:text-mj-php-mellow" /></a>
            </div>
          </div>
        </div>

<!-- Apppliance People -->
        <div class="relative flex flex-col-reverse lg:flex-row w-full mb-14">
          <div>
            <a href="https://appliancepeople.co.uk/" target="_blank" rel="noopener"><img class="transition duration-200 opacity-60 hover:opacity-80" src="../assets/ap.png" alt="Appliance People Project"></a>
          </div>
          <div class="lg:absolute right-0 top-10 lg:w-1/2 lg:text-right">
            <h3 class="text-2xl mb-4 text-mj-php-mellow">Appliance People</h3>
            <div class="bg-mj-raisin-light p-4 rounded mb-4 shadow-md">
              <p>Custom <span class="text-mj-php-mellow">Shopify</span> theme for Appliance People. Built in mind to easily navigate through a few thousand products and create an enjoyable online shopping experience.</p>
            </div>
            <ul class="flex lg:flex-row-reverse gap-x-5 font-mono text-sm mb-3 lg:pl-28 flex-wrap gap-y-2">
              <li>VueJS</li>
              <li>JavaScript</li>
              <li>Liquid</li>
              <li>SCSS</li>
              <li>HTML</li>
              <li>Shopify</li>
              <li>Webpack</li>
            </ul>
            <div class="flex items-center lg:flex-row-reverse gap-5 mb-6 lg:mb-0">
              <a href="https://github.com/mjurkian/shopify-custom-theme" title="GitHub" rel="noopener" target="_blank" class="transition duration-200 hover:text-mj-php-mellow"><svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" width="20px" height="20px" fill="none" stroke="#E9EBF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="stroke-current"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg></a>
              <a href="https://appliancepeople.co.uk/" title="External Link" target="_blank" rel="noopener"><ExternalLinkIcon class="w-6 h-6 text-mj-lavender transition duration-200 hover:text-mj-php-mellow" /></a>
            </div>
          </div>
        </div>

<!-- Tallents Solicitors -->
        <div class="relative flex flex-col-reverse lg:flex-row-reverse w-full mb-14">
          <div>
            <a href="https://www.tallents.co.uk/" target="_blank" rel="noopener"><img class="transition duration-200 opacity-60 hover:opacity-80" src="../assets/tallents.png" alt="Tallents Solicitors Project"></a>
          </div>
          <div class="lg:absolute left-0 top-10 lg:w-1/2 lg:text-left">
            <h3 class="text-2xl mb-4 text-mj-php-mellow">Tallents Solicitors</h3>
            <div class="bg-mj-raisin-light p-4 rounded mb-4 shadow-md">
              <p>Full range of private and business legal services on this <span class="text-mj-php-mellow">WordPress</span> built from scratch website.</p>
            </div>
            <ul class="flex lg:flex-row gap-x-5 font-mono text-sm mb-3 lg:pr-28 flex-wrap gap-y-2">
              <li>HTML</li>
              <li>SCSS</li>
              <li>PHP</li>
              <li>JavaScript</li>
              <li>Webpack</li>
              <li>WordPress</li>
              <li>ACF</li>
            </ul>
            <div class="flex items-center lg:flex-row gap-5 mb-6 lg:mb-0">
              <a href="https://github.com/mjurkian/tallents-wp-theme" title="GitHub" rel="noopener" target="_blank" class="transition duration-200 hover:text-mj-php-mellow"><svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" width="20px" height="20px" fill="none" stroke="#E9EBF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="stroke-current"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg></a>
              <a href="https://www.tallents.co.uk/" title="External Link" target="_blank" rel="noopener"><ExternalLinkIcon class="w-6 h-6 text-mj-lavender transition duration-200 hover:text-mj-php-mellow" /></a>
            </div>
          </div>
        </div>

<!-- Arvalet -->
        <div class="relative flex flex-col-reverse lg:flex-row w-full">
          <div>
            <a href="https://arvalet.com/" target="_blank" rel="noopener"><img class="transition duration-200 opacity-60 hover:opacity-80" src="../assets/arvalet.png" alt="Arvalet Auto Center Project"></a>
          </div>
          <div class="lg:absolute right-0 top-10 lg:w-1/2 lg:text-right">
            <h3 class="text-2xl mb-4 text-mj-php-mellow">Arvalet Auto Center</h3>
            <div class="bg-mj-raisin-light p-4 rounded mb-4 shadow-md">
              <p>Designed and created for exceptional digital experiences when looking for all your car needs. <span class="text-mj-php-mellow">WordPress</span> Theme built with easy to use ACF Blocks.</p>
            </div>
            <ul class="flex lg:flex-row-reverse gap-x-5 font-mono text-sm mb-3 lg:pl-28 flex-wrap gap-y-2">
              <li>Webpack</li>
              <li>JavaScript</li>
              <li>PHP</li>
              <li>SCSS</li>
              <li>HTML</li>
              <li>WordPress</li>
              <li>ACF</li>
            </ul>
            <div class="flex items-center lg:flex-row-reverse gap-5 mb-6 lg:mb-0">
              <a href="https://github.com/mjurkian/auto-center-composer" title="GitHub" rel="noopener" target="_blank" class="transition duration-200 hover:text-mj-php-mellow"><svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" width="20px" height="20px" fill="none" stroke="#E9EBF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="stroke-current"><title>GitHub</title><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg></a>
              <a href="https://arvalet.com/" title="External Link" target="_blank" rel="noopener"><ExternalLinkIcon class="w-6 h-6 text-mj-lavender transition duration-200 hover:text-mj-php-mellow" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {ExternalLinkIcon} from '@heroicons/vue/outline'

export default {
  name: 'Projects',
  components: {
    ExternalLinkIcon,
  }
}
</script>
