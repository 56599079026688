<template>
  <div class="w-full text-center p-2 border-t-2 border-mj-raisin-dark">
    <a class="text-sm transition duration-500 hover:text-mj-php-mellow" href="https://github.com/mjurkian/v2" title="GitHub Link" target="_blank" rel="noopener">Designed and Built by Michal Jurkian © 2021</a>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
