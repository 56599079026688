<template>
  <section id="contact" class="relative max-w-screen-lg mx-auto ">
    <div class="container flex flex-col-reverse items-center gap-12 lg:flex-row pt-28">
      <div class="flex flex-col flex-1 mb-40">
        <div class="lg:-ml-3 pl-3 pt-3 border-l-2 border-mj-php-mellow mb-8 rounded">
          <p class="text-mj-php-mellow text-xs mb-2 uppercase">Say hello</p>
          <h2 class="pb-3 text-3xl text-mj-php-mellow md:text-4xl lg:text-left">Contact</h2>
        </div>
        <h3 class="m-auto text-3xl text-mj-lavender mb-6 text-mj-php-mellow">Get In Touch</h3>
        <p class="mb-6 text-lg text-mj-lavender lg:w-1/2 text-center m-auto mb-10">I’m looking for new opportunities, drop me a message if you have any question and I will be happy to answer. My inbox is always open</p>
        <a class="btn btn-mellow m-auto" href="mailto:michaljurkian@gmail.com">Email</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
}
</script>
